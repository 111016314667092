import React from "react";

import {useTranslation} from "react-i18next";
import * as S from "./styles";

/**
 * Cart header to use with conjunction of cart rows.
 */
const CartHeader: React.FC = () => {
    const {t} = useTranslation();
    return (
    <S.Wrapper>
      <S.Column>{t("Products")}</S.Column>
      <S.Column>{t("Price")} {t("VAT excluded")}</S.Column>
      <S.Column>{t("Quantity")}</S.Column>
      <S.Column>{t("Total VAT excluded")}</S.Column>
    </S.Wrapper>
  );
};

export { CartHeader };
