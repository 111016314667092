import "./scss/index.scss";

import * as React from "react";

import { BootStrap } from "@temp/components/BootStrap";
import Nav from "./Nav";

const StaticFooter = () => (<BootStrap className="row">
        <div className="col-12 col-md-2 mb-4">
            <img src="/images/erimell_logo.png" alt="" height={19} />
        </div>

        <div className="col-12 col-md-2">
            <p><strong className="text-danger">Küsimuste tekkimisel</strong><br />
                <a className="link-dark" href="mailto:ott@erimell.ee">ott@erimell.ee</a><br />
                <a className="link-dark" href="tel:5267529">5267529</a> (Ott)<br />
            </p>
        </div>

        <div className="col-6 col-md-2">
            <p><strong>Tartu esindus</strong><small><br />
                Viljandi mnt. 73, 61713 Tartu<br />
                Avatud E-R 8-18, L 10-15<br />
                Tel: <a className={"link-dark"} href="tel:7400455">740 0455</a><br />
                Fax: 740 0454<br />
                <a className="link-dark" href="mailto:erimell@erimell.ee">erimell@erimell.ee</a></small>
            </p>
        </div>
        <div className="col-6 col-md-2">
            <p><strong>Rakvere esindus</strong><small><br />
                Narva tn. 40, 44311 Rakvere<br />
                Avatud E-R 8-17<br />
                Tel: <a className={"link-dark"} href="tel:3223309">322 3309</a><br />
                Fax 322 4792<br />
                <a className="link-dark" href="mailto:rakvere@erimell.ee">rakvere@erimell.ee</a></small>
            </p>
        </div>
        <div className="col-6 col-md-2">
            <p><strong>Saue esindus</strong><small><br />
                Sooja tn. 4, 76505 Saue<br />
                Avatud E-R 8-17<br />
                Tel: <a className={"link-dark"} href="tel:6709400">670 9400</a><br />
                Fax: 659 6405<br />
                <a className="link-dark" href="mailto:saue@erimell.ee">saue@erimell.ee</a></small>
            </p>
        </div>
        <div className="col-6 col-md-2">
            <p><strong>Pärnu esindus</strong><small><br />
                Tallinna mnt. 107, 80043 Pärnu<br />
                Avatud E-R 8-17<br />
                Tel: <a  className="link-dark" href="tel:4429710">442 9710</a><br />
                Fax: 442 9681<br />
                <a className="link-dark" href="mailto:parnu@erimell.ee">parnu@erimell.ee</a></small>
            </p>
        </div>
    </BootStrap>
)

const Footer: React.FC = () => (
    <div className={"container-xxl"}>
        <footer className="pt-4 my-md-5 pt-md-5 border-top">
            <StaticFooter />
            <Nav />
        </footer>
    </div>
);

export default Footer;
