import React from "react";

import {useUserDetails} from "@sdk/react";
import {OverlayContext, OverlayTheme, OverlayType} from "@temp/components";
import {Trans} from "react-i18next";
import AddToCartButton from "./AddToCartButton";

const AddToCart: React.FC<{
    disabled: boolean;
    onSubmit: () => void;
}> = ({onSubmit, disabled}) => {
    const {data: user} = useUserDetails();

    return (
        <OverlayContext.Consumer>
            {overlayContext => (
                <AddToCartButton
                    className="product-description__action"
                    onClick={() => {
                        user ? onSubmit() :
                            overlayContext.show(
                              OverlayType.login,
                              OverlayTheme.modal
                            );
                    }}
                    disabled={disabled}
                >
                    <Trans>Add to basket</Trans>
                </AddToCartButton>
            )}
        </OverlayContext.Consumer>
    );
};

export default AddToCart;
