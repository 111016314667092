/**
 *
 * Resets back to Bootstrap
 *
 * For bootstrap rules, which might mess up the existing site, but are required to make things look OK
 *
 * Use this component to keep their effects limited to the bootstrap content being dropped.
 *
 * Mostly a stripped down version of normalize.css used in bootstrap + bootstrap vars.
 *
 */

import {styled} from "@styles";

export const BootStrap = styled.div`


    // Color system
    --white:    #fff;
    --gray-100: #f8f9fa;
    --gray-200: #e9ecef;
    --gray-300: #dee2e6;
    --gray-400: #ced4da;
    --gray-500: #adb5bd;
    --gray-600: #6c757d;
    --gray-700: #495057;
    --gray-800: #343a40;
    --gray-900: #212529;
    --black:    #000;

    --font-size-base: 1rem;
    --font-size-lg:                var(--font-size-base) * 1.25;
    --font-size-sm:                var(--font-size-base) * .875;

    --font-weight-lighter: lighter;
    --font-weight-light: 300;
    --font-weight-normal: 400;
    --font-weight-bold: 700;
    --font-weight-bolder: bolder;

    --paragraph-margin-bottom:   1rem;


    --font-weight-base: var(--font-weight-normal);
    --line-height-base: 1.5;

    --h1-font-size:               var(--font-size-base) * 2.5;
    --h2-font-size:               var(--font-size-base) * 2;
    --h3-font-size:               var(--font-size-base) * 1.75;
    --h4-font-size:               var(--font-size-base) * 1.5;
    --h5-font-size:               var(--font-size-base) * 1.25;
    --h6-font-size:var(--font-size-base);

    --headings-margin-bottom:      --spacer / 2;
    --headings-font-family: null;
    --headings-font-weight: 500;
    --headings-line-height: 1.2;
    --headings-color: null;

    --display1-size: 6rem;
    --display2-size: 5.5rem;
    --display3-size: 4.5rem;
    --display4-size: 3.5rem;

    --display1-weight: 300;
    --display2-weight: 300;
    --display3-weight: 300;
    --display4-weight: 300;
    --display-line-height:var(--headings-line-height);
    --lead-font-size:             var(--font-size-base) * 1.25;
    --lead-font-weight: 300;

    --small-font-size: 80%;

    --text-muted:var(--gray-600);
    --blockquote-small-color: --gray-600;
    --blockquote-small-font-size: --small-font-size;
    --blockquote-font-size:        --font-size-base * 1.25;

    --hr-border-color: rgba(--black, .1);
    --hr-border-width:var(--border-width);
    --mark-padding: .2em;

    --dt-font-weight:var(--font-weight-bold);
    --kbd-box-shadow: inset 0 -.1rem 0 rgba(--black, .25);
    --nested-kbd-font-weight:var(--font-weight-bold);
    --list-inline-padding: .5rem;

    --mark-bg: #fcf8e3;

    --hr-margin-y:var(--spacer);

    h1,h2,h3,h4,h5,h6 {
      margin-top: 0; // 1
      margin-bottom:var(--headings-margin-bottom);
    font-family:var(--headings-font-family);
    font-style:var(--headings-font-style);
    font-weight:var(--headings-font-weight);
    line-height:var(--headings-line-height);
    }

    h1 {
     font-size: var(--h1-font-size);
    }

    h2 {
      font-size: var(--h2-font-size);
    }

    h3 {
      font-size: var(--h3-font-size);
    }

    h4 {
      font-size: var(--h4-font-size);
    }

    h5 {
     font-size: var(--h5-font-size);
    }

    h6 {
     font-size: var(--h6-font-size);
    }


    // Reset margins on paragraphs
    //
    // Similarly, the top margin on \`<p>\`s get reset. However, we also reset the
    // bottom margin to use \`rem\` units instead of \`em\`.

    p {
        margin-top: 0;
        margin-bottom: var(--paragraph-margin-bottom);
    }


    // Lists

    ol,
    ul {
        padding-left: 2rem;
    }

    ol,
    ul,
    dl {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    ol ol,
    ul ul,
    ol ul,
    ul ol {
        margin-bottom: 0;
    }

    dt {
        font-weight: var(--dt-font-weight);
    }

    // 1. Undo browser default

    dd {
        margin-bottom: .5rem;
        margin-left: 0; // 1
    }


    // Blockquote

    blockquote {
        margin: 0 0 1rem;
    }


    // Strong
    //
    // Add the correct font weight in Chrome, Edge, and Safari

    b,
    strong {
        font-weight: var(--font-weight-bolder);
    }


    // Small
    //
    // Add the correct font size in all browsers

    small {
      font-size: var(--small-font-size);
    }


    // Sub and Sup
    //
    // Prevent \`sub\` and \`sup\` elements from affecting the line height in
    // all browsers.

    a {
        text-decoration: underline;
    }

    sub,
    sup {
        position: relative;
        font-size: var(--sub-sup-font-size);
        line-height: 0;
        vertical-align: baseline;
    }

    sub {
        bottom: -.25em;
    }

    sup {
        top: -.5em;
    }
`;
