import React from "react";

import { ErrorMessage } from "@components/atoms";
import { AddressSummary } from "@components/molecules";

import {Trans, useTranslation} from "react-i18next";
import * as S from "./styles";
import { IProps } from "./types";

/**
 * Review order view showed in checkout.
 */
const CheckoutReview: React.FC<IProps> = ({
  shippingAddress,
  billingAddress,
  shippingMethodName,
  shippingRemark,
  paymentMethodName,
  email,
  errors,
}: IProps) => {

  const { t } = useTranslation();

  return (
    <S.Wrapper>
        <S.Title data-cy="checkoutPageSubtitle"><Trans>REVIEW ORDER</Trans>
            <br/><div><Trans>Please confirm your order at the bottom of the page</Trans></div></S.Title>
      <S.Grid>
          <S.DisclamerSection>
          <S.SubTitle data-cy="checkoutReviewSectionTitle">
              <i className="pi pi-info-circle"/> <span><Trans>Attention</Trans>!</span>
          </S.SubTitle>
          <S.Divider />
          <S.TextSummary>{t("VAT-disclamer").replaceAll("{email}", email)}</S.TextSummary>
          </S.DisclamerSection>
        <section>
          <S.SubTitle data-cy="checkoutReviewSectionTitle">
              <Trans>Shipping Address</Trans>
          </S.SubTitle>
          <S.Divider />
          <AddressSummary address={shippingAddress} email={email} />
        </section>
        <section>
          <S.SubTitle data-cy="checkoutReviewSectionTitle">
              <Trans>Billing Address</Trans>
          </S.SubTitle>
          <S.Divider />
          <AddressSummary address={billingAddress} email={email} />
        </section>
        <section>
          <S.SubTitle data-cy="checkoutReviewSectionTitle">
              <Trans>Shipping Method</Trans>
          </S.SubTitle>
          <S.Divider />
          <S.TextSummary>{shippingMethodName}</S.TextSummary>
        </section>
        {shippingRemark &&
            <section><S.SubTitle data-cy="checkoutReviewSectionTitle">
                <Trans>Shipping Remark</Trans>
            </S.SubTitle>
                <S.Divider />
                <S.TextSummary>{shippingRemark}</S.TextSummary>
            </section>}
        <section>
          <S.SubTitle data-cy="checkoutReviewSectionTitle">
            <Trans>Payment Method</Trans>
          </S.SubTitle>
          <S.Divider />
            <S.TextSummary><Trans>By invoice</Trans></S.TextSummary>
        </section>
      </S.Grid>
      <S.ErrorMessages>
        <ErrorMessage errors={errors} />
      </S.ErrorMessages>
    </S.Wrapper>
  );
};

export { CheckoutReview };
