import React, { useEffect, useState } from "react";

import { TextField } from "@components/molecules";
import {useTranslation} from "react-i18next";

interface QuantityTextFieldProps {
  quantity: number;
  maxQuantity: number;
  disabled: boolean;
  onQuantityChange: (value: number) => void;
  hideErrors: boolean;
  label?: string;
contentLeft?: React.ReactNode;
  contentRight?: React.ReactNode;
}

export const QuantityTextField: React.FC<QuantityTextFieldProps> = ({
  disabled,
  quantity,
  maxQuantity,
  onQuantityChange,
  hideErrors,
  contentLeft,
  contentRight,
  label = "Quantity",
}: QuantityTextFieldProps) => {
  const [isTooMuch, setIsTooMuch] = useState(false);

  useEffect(() => {
    setIsTooMuch(!isNaN(quantity) && quantity > maxQuantity);
  }, [quantity, maxQuantity]);

  const {t} = useTranslation()
  const handleQuantityChange = (evt: React.ChangeEvent<any>) => {
    const newQuantity = parseInt(evt.target.value, 10);

    if (quantity !== newQuantity) {
      onQuantityChange(newQuantity);
    }
    setIsTooMuch(!isNaN(newQuantity) && newQuantity > maxQuantity);
  };

  const quantityErrors =
    !hideErrors && isTooMuch
      ? [
          {
            message: `${t("Maximum quantity is")} ${maxQuantity}`,
          },
        ]
      : undefined;

  return (
    <TextField
      type="number"
      label={t(label)}
      min="0"
      value={quantity.toString()}
      disabled={disabled}
      onChange={handleQuantityChange}
      errors={quantityErrors}
      contentLeft={contentLeft}
      contentRight={contentRight}
    />
  );
};
