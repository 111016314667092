import {styled} from "@styles";

export const Price = styled.span`
display: flex;
flex-direction: column;
gap: .5ch;
font-size: 18px;
line-height: 17px;
.netmoney{
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.5);
    ::after{
     content: "+KM";
     font-size: 10px;
     }
    &.perKg::after{
      content: "+KM/Kg";
    }
    &.perL::after{
      content: "+KM/L";
    }
}
    `
