import "./scss/index.scss";

import * as React from "react";
import { Link } from "react-router-dom";

import {NavigationMenu} from "@components/organisms/SideNavbar/SideMenu";
import { baseUrl } from "../../app/routes";
import logoImg from "../../images/erimell_logo.png";
import { INavItem } from "./NavItem";

interface NavListProps {
  items: INavItem[];
  hideOverlay(): void;
}

class NavList extends React.PureComponent<NavListProps > {
    render() {
    const { hideOverlay, items } = this.props;
    return (
      <ul>
            <li className="side-nav__menu-item side-nav__menu-item--parent">
              <Link
                to={baseUrl}
                className="side-nav__menu-item-logo"
                onClick={hideOverlay}
              >
                <img src={logoImg} alt={"Erimell"} style={{maxHeight:"min(100%, 5ch)"}}/>
              </Link>
              <span className="side-nav__menu-item-close" onClick={hideOverlay}>
                <span />
              </span>
            </li>
        <NavigationMenu items={items}/>
      </ul>
    );
  }
}

export default NavList;
