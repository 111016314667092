import React from "react";

import { Button } from "@components/atoms";
import { Container } from "@components/templates";

import {Trans} from "react-i18next";
import * as S from "./styles";
import { IProps } from "./types";

/**
 * Thank you page after completing the checkout.
 */
const ThankYou: React.FC<IProps> = ({
  orderNumber,
  continueShopping,
  orderDetails,
}: IProps) => {
  return (
    <Container>
      <S.Wrapper>
        <S.ThankYouHeader>
            <Trans>Thank you</Trans>
          <br />
            <span><Trans>for your order!</Trans></span>
        </S.ThankYouHeader>
        <S.Paragraph>
            <Trans>Your order number is</Trans> <span>{orderNumber}</span>
        </S.Paragraph>
        <S.Paragraph>
          <Trans>We've emailed you an order confirmation, and we'll notify you the when order has been shipped</Trans>
        </S.Paragraph>
        <S.Buttons>
          <Button onClick={continueShopping} color="secondary" fullWidth={true}>
              <Trans>Continue shopping</Trans>
          </Button>
          <Button onClick={orderDetails} fullWidth={true}>
              <Trans>ORDER DETAILS</Trans>
          </Button>
        </S.Buttons>
      </S.Wrapper>
    </Container>
  );
};

export { ThankYou };
