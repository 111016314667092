import React from "react";
import {useTranslation} from "react-i18next";

const ForgottenPassword: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => {
    const {t} = useTranslation()
   return  <>
        <div className="login__content__password-reminder">
            <p>
                {t("Have you forgotten your password")}?&nbsp;
                <span className="u-link" onClick={onClick}>
          {t("Click here")}
        </span>
            </p>
        </div>
    </>
};

export default ForgottenPassword;
