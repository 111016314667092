import React from "react";

import * as S from "./styles";
import { IProps } from "./types";

import {useTranslation} from "react-i18next";
import { Container } from "../Container";

/**
 * Template for empty cart page.
 */
const CartEmpty: React.FC<IProps> = ({ button }: IProps) => {
  const {t} = useTranslation();
  return (
    <Container>
      <S.Wrapper>
        <S.TitleFirstLine>{t("Your Cart")}</S.TitleFirstLine>
        <S.TitleSecondLine>{t("looks empty")}</S.TitleSecondLine>
        <S.HR />
        <S.Subtitle>{t("Maybe you haven’t made your choices yet")}</S.Subtitle>
        <S.ContinueButton>{button}</S.ContinueButton>
      </S.Wrapper>
    </Container>
  );
};

export { CartEmpty };
