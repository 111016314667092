import {Money} from "@components/containers";
import classNames from "classnames";
import React from "react";
import * as S from "./styles";
import { IProps } from "./types";

export const MoneyPer: React.FC<IProps> = ({
  taxedMoney,
  value,
  unitLabel,
  net,
}: IProps) => {
  const price = net ? taxedMoney.net : taxedMoney.gross
  const pricePer = price.amount / value;
    if (isNaN(pricePer) || (!unitLabel && !value) ) {
      return null
  }
  return (
    <S.Price><span className={classNames( `per${unitLabel}`,{"netmoney": net, "grossmoney":!net})}><Money
                money={{amount: pricePer, currency: price.currency}} /></span></S.Price>
  );
};

MoneyPer.defaultProps = {
    net : true,
    value : 1,

}

MoneyPer.displayName = "MoneyPer";

export default MoneyPer;
