import "./scss/index.scss";

import * as React from "react";

import {MetaWrapper} from "../../components";
import Page from "./Page";
import {TypedHomePageQuery} from "./queries";

import {NavigationMenu} from "@components/organisms/SideNavbar/SideMenu";
import {TypedMainMenuQuery} from "@temp/components/MainMenu/queries";
import {maybe} from "@temp/core/utils";
import {CateogryView, REHVID, ViewProps} from "../Category/View";


const View: React.FC<ViewProps> = (viewprops) => {
    const {category_id, category_slug, collection_id} = viewprops.match.params
    // On main index show all, otherwise filter/exclude tires based on if we're looking at the tires category
    const isTires = (viewprops.match.url === "/") ? undefined : category_slug === REHVID;
    return <>
        <TypedHomePageQuery alwaysRender displayLoader={false} errorPolicy="all">
            {({data}) => {
                return (
                    <MetaWrapper
                        meta={{
                            description: data.shop ? data.shop.description : "",
                            title: data.shop ? data.shop.name : "",
                        }}
                    >
                        {!!data.shop && <Page
                            shop={data.shop}
                        />}
                    </MetaWrapper>
                );
            }}
        </TypedHomePageQuery>

        <div className={"container-xxl"}>
            <div className="row">
                <div className="col-lg-3 d-none d-lg-block col-0 home-page">
                    <TypedMainMenuQuery renderOnError displayLoader={false}>
                        {({data}) => {
                            const items = maybe(() => data.shop.navigation.main.items, []);

                            return (<NavigationMenu items={items} />)
                        }}
                    </TypedMainMenuQuery>

                </div>
                <div className={"col-lg-9 col-12"}>
                    {CateogryView({category_id, collection_id, isTires})}
                </div>
            </div>
        </div>
    </>
};

export default View;
