import * as React from "react";

import {Trans} from "react-i18next";
import {Button} from "../..";

const Empty: React.FC<{ overlayHide(): void }> = ({overlayHide}) => (
    <div className="cart__empty">
        <h4><Trans>Your Cart looks empty</Trans></h4>
        <p>
            <Trans>BAG EMPTY_TEXT</Trans>
        </p>
        <div className="cart__empty__action">
            <Button secondary onClick={overlayHide}>
                <Trans>Continue shopping</Trans>
            </Button>
        </div>
    </div>)
;

export default Empty;
