import * as React from "react";

import {styled} from "@styles";
import {MainMenuSubItem} from "@temp/components/MainMenu/gqlTypes/MainMenuSubItem";
import {generateMenuUrl} from "@temp/core/utils";
import classNames from "classnames";
import {NavLink} from "react-router-dom";

const NavList = styled.ul`
    padding-left: 2rem;
    margin-bottom: 0.5rem;

    a {
        padding: .13rem .5rem;
        border-radius: 0.3em;
        transition: all 200ms ease-in-out;
        display: block;
        width: fit-content;
        height: fit-content;

        &:hover {
            background-color: rgba(236, 28, 36, 0.23) !important;
        }

        &:active, &:focus {
            background-color: rgba(236, 28, 36, 0.23);
        }

        &.active {
            background-color: initial;
            font-weight: ${(props) => props.theme.typography.boldFontWeight};
        }

    }

    .hasChildren, h4 {
        text-transform: uppercase;

        a {
         font-weight: ${(props) => props.theme.typography.boldFontWeight};
            &.active {
            font-weight: ${(props) => props.theme.typography.extraBoldFontWeight};
        }
        }

    }

    h2 {
        font-size: xx-large;
        margin-top: 1rem;
    }

    h3 {
        margin-top: 1rem;
        font-size: x-large;
    }

    h2, h3, h4 {
        margin-bottom: 0.5rem;
        margin-top: 1.5rem;
        display: inline-block;
    }
    li {
        display: block;
    }
    ul {
        padding-left: 0.5rem;
    }
`
const MenuText: React.FC<{ level: number, hasChildren: boolean }>
    = ({level, hasChildren, children}) => {
    if (level === 0) {
        return <h4>{children}</h4>
    }
    return <span className={classNames({hasChildren})}>{children}</span>
}
const NavigationMenuItem: React.FC<{
    item: MainMenuSubItem,
    children?:MainMenuSubItem[],
    level?: number
}> = (
    {
        item: {
            collection,
            category,
            name,
            url,
            productCount,

        },
        children,
        level = 0,
    }
) => {

    if ((collection || category) && productCount === 0) {
        // We want to not render empty menu items.
        // did think to filter if no cat col or url, but if there is just a Name we should still render.
        return null
    }
    return <li>
            <MenuText level={level}
                      hasChildren={!!children?.length} >
                        <NavLink
            to={{
                pathname: generateMenuUrl({collection, category, url}),
            }}
        >
        {name} {!!productCount && <span>({productCount})</span>}
        </NavLink>

            </MenuText>

        {!!children && <ul>
            {children.map((child) => <NavigationMenuItem item={child} children={child.children} key={child.id}
                                                         level={level + 1} />)}
        </ul>}
    </li>
}
export const NavigationMenu: React.FC<{ items: MainMenuSubItem[]; }> = ({items}) => {
    return <NavList className="cateogry-nav__list">{items.map((item) => <NavigationMenuItem item={item}
                                                                                                     children={item.children}
                                                                                                     key={item.id} />)}</NavList>
}
