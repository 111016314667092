/* Client-side translation configuration */
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

// Hacky solution for getting i18n working in FE.

export const SETTINGS = { // copied from app/i18n.json
  "LANGUAGE_COOKIE_NAME": 'erimell_language',
  "DEFAULT_NAMESPACE": "translations",
  "TRANSLATION_NAMESPACES": [
    "translations"
  ],
  "FALLBACK_LANGUAGE": "en",
  "DEFAULT_LANGUAGE": "et",
  "LANGUAGE_ORDER": [
    "en",
    "et"
  ],
  "LANGUAGES": {
    "en": "English",
    "et": "Eesti keel"
  }
}

import {translations} from './translations';

const defaultOptions = {
    load: 'languageOnly', // No region-specific locales (en-US, de-DE, etc.)
    fallbackLng: SETTINGS.DEFAULT_LANGUAGE,
    ns: SETTINGS.TRANSLATION_NAMESPACES,
    defaultNS: SETTINGS.DEFAULT_NAMESPACE,
    returnEmptyString: false,
    saveMissing: true,
    saveMissingTo: 'all',
    debug: false,
    interpolation: {
        escapeValue: false, // Not needed for React
    },
    react: {
        // Currently Suspense is not server ready
        useSuspense: false,
        // Client-side always waits for translations
        wait: true,
    },
    // Backend fetching isn't working so this will do for now.
    // Translations are in a list format to keep things by default the key the english version.
    resources: {
        en: {
            translations: {
                ...translations.reduce((acc, curr) => (acc[curr[0]] = (curr[2]||curr[0]), acc), {}),
            }
        },
        et: {
            translations: {
                ...translations.reduce((acc, curr) => (acc[curr[0]] = curr[1], acc), {}),
            }
        }
    }
};

i18next.use(initReactI18next);

export const setupI18Next = async (language) => {
    if (!i18next.isInitialized) {
        // Initialize with default options while setting language as well
        await i18next.init({
            lng: language,
            ...defaultOptions,
        });
    }

    return i18next;
};

export default i18next;
