import * as React from "react";
import { RouteComponentProps } from "react-router";

import {useUserDetails} from "@sdk/react";
import { IFilters } from "@types";
import {useTranslation} from "react-i18next";
import { StringParam, useQueryParam } from "use-query-params";
import { OfflinePlaceholder } from "../../components";
import NetworkStatus from "../../components/NetworkStatus";
import { PRODUCTS_PER_PAGE } from "../../core/config";
import {
  convertSortByFromString,
  convertToAttributeScalar,
  getGraphqlIdFromDBId,
  maybe,
} from "../../core/utils";
import Page from "./Page";
import { TypedCategoryProductsQuery} from "./queries";

export const REHVID = "rehvid";

export type ViewProps = RouteComponentProps<{
  category_id: string,
  collection_id?: string,
  category_slug?: string,
  collection_slug?: string;
}>;

interface CateogryViewProps<>{
  category_id?: string,
  collection_id?: string,
  isTires?:boolean;
}


export const FilterQuerySet = {
  encode(valueObj) {
    const str = [];
    Object.keys(valueObj).forEach(value => {
      str.push(value + "_" + valueObj[value].join("_"));
    });
    return str.join(".");
  },

  decode(strValue) {
    const obj = {};
    const propsWithValues = strValue.split(".").filter(n => n);
    propsWithValues.map(value => {
      const propWithValues = value.split("_").filter(n => n);
      obj[propWithValues[0]] = propWithValues.slice(1);
    });
    return obj;
  },
};

export const View: React.FC<ViewProps> = ({ match }) => {
    const {category_id, category_slug, collection_id} = match.params;
    const isTires = category_slug === REHVID;
    return CateogryView({category_id, collection_id, isTires})
}

export const CateogryView: React.FC<CateogryViewProps> = ({category_id, collection_id, isTires}) => {
  const [sort, setSort] = useQueryParam("sortBy", StringParam);
  const [search, setSearch] = useQueryParam("q", StringParam);
  const [attributeFilters, setAttributeFilters] = useQueryParam(
    "filters",
    FilterQuerySet
  );
  const { data: user } = useUserDetails();
  const loggedIn = !!user

  const clearFilters = () => {
    setAttributeFilters({});
  };

  const onFiltersChange = (name, value) => {
    if (attributeFilters && attributeFilters.hasOwnProperty(name)) {
      if (attributeFilters[name].includes(value)) {
        if (filters.attributes[`${name}`].length === 1) {
          const att = { ...attributeFilters };
          delete att[`${name}`];
          setAttributeFilters({
            ...att,
          });
        } else {
          setAttributeFilters({
            ...attributeFilters,
            [`${name}`]: attributeFilters[`${name}`].filter(
              item => item !== value
            ),
          });
        }
      } else {
        setAttributeFilters({
          ...attributeFilters,
          [`${name}`]: [...attributeFilters[`${name}`], value],
        });
      }
    } else {
      setAttributeFilters({ ...attributeFilters, [`${name}`]: [value] });
    }
  };

  category_id =category_id && getGraphqlIdFromDBId(category_id, "Category")
  collection_id =collection_id && getGraphqlIdFromDBId(collection_id, "Collection")

  const filters: IFilters = {
    attributes: attributeFilters,
    pageSize: PRODUCTS_PER_PAGE,
    priceGte: null,
    priceLte: null,
    sortBy: sort || null,
  };
  const variables = {
      pageSize: filters.pageSize,
      sortBy: convertSortByFromString(filters.sortBy),
      with_category: false,
      with_collection: false,

      ...category_id ? {
          category_id,
          with_category: true,
      } : {},
      ...collection_id ? {
          collection_id,
          with_collection: true,
      } : {},
      logged_in: loggedIn,
      products_filter: {
          ...category_id ? {categories: [category_id]} : {},
          ...collection_id ? {collections: [collection_id]} : {},
          ...search ? {search} : {},
          attributes: filters.attributes ? convertToAttributeScalar(filters.attributes) : {},
          minimalPrice: {gte: null, lte: null},
      },

      attributes_filter: {
        ...category_id?{inCategory:category_id}:{},
        ...collection_id?{inCollection:collection_id}:{},
    },
}
  const {t} = useTranslation();

  const sortOptions = [
    {
      label: "Clear",
      value: null,
    },
    {
      label: t("Price Low-High"),
      value: "price",
    },
    {
      label: t("Price High-Low"),
      value: "-price",
    },
    {
      label: t("Name Increasing"),
      value: "name",
    },
    {
      label: t("Name Decreasing"),
      value: "-name",
    },
    {
      label: t("Last updated Ascending"),
      value: "updated_at",
    },
    {
      label: t("Last updated Descending"),
      value: "-updated_at",
    },
  ];

  return (
    <NetworkStatus>
      {isOnline => (
        <TypedCategoryProductsQuery
          variables={variables}
          errorPolicy="ignore"
          displayError={false}
          displayLoader={false}
          alwaysRender={true}
        >
          {({ loading, data, loadMore }) => {
            if (!isOnline) {
              return <OfflinePlaceholder />;
            }
              const handleLoadMore = () =>
                loadMore(
                  (prev, next) => {
                     if (prev===undefined){
                         return next
                     }
                      return ({
                          ...prev,
                          products: {
                              ...prev.products,
                              edges: [...prev.products.edges, ...next.products.edges],
                              pageInfo: next.products.pageInfo,
                          },
                      });
                  },
                  { after: data.products.pageInfo.endCursor }
                );
              if (!loading && data.products && !data.products.pageInfo.hasPreviousPage && data.products.edges.length < 30 && data.products.edges.length > 0 ){
                  handleLoadMore()
              }
              return (
                  <Page
                    clearFilters={clearFilters}
                    attributes={[]/*data.attributes.edges.map(edge => edge.node)*/}
                    category={data.category}
                    collection={data.collection}
                    loading={loading}
                    displayLoader={false}
                    hasNextPage={maybe(
                      () => !!data.products?.pageInfo?.hasNextPage,
                      false
                    )}
                    setSearch={setSearch}
                    search={search}
                    sortOptions={sortOptions}
                    activeSortOption={filters.sortBy}
                    filters={filters}
                    products={data.products}
                    onAttributeFiltersChange={onFiltersChange}
                    onLoadMore={handleLoadMore}
                    activeFilters={
                      filters!.attributes
                        ? Object.keys(filters!.attributes).length
                        : 0
                    }
                    onOrder={value => {
                      setSort(value.value);
                    }}
                    isTires={isTires}
                  />
              );


          }}
        </TypedCategoryProductsQuery>
      )}
    </NetworkStatus>
  );
};

export default View;
