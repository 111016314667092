import React from "react";

import { Checkbox } from "@components/atoms";
import { filterNotEmptyArrayItems } from "@utils/misc";

import { AddressForm } from "../AddressForm";
import { AddressGridSelector } from "../AddressGridSelector";
import { PaymentGatewaysList } from "../PaymentGatewaysList";

import {Trans, useTranslation} from "react-i18next";
import * as S from "./styles";
import { IProps } from "./types";

/**
 * Payment options used in checkout.
 */
const CheckoutPayment: React.FC<IProps> = ({
  gatewayErrors,
  billingErrors,
  selectedUserAddressId,
  userAddresses,
  billingAsShippingAddress,
  checkoutBillingAddress,
  countries,
  billingFormRef,
  billingFormId,
  paymentGateways,
  setBillingAddress,
  billingAsShippingPossible,
  setBillingAsShippingAddress,
  selectedPaymentGateway,
  selectedPaymentGatewayToken,
  selectPaymentGateway,
  gatewayFormRef,
  gatewayFormId,
  userId,
  newAddressFormId,
  processPayment,
  onGatewayError,
}: IProps) => {
  const {t} = useTranslation();
  const adresses =
    userAddresses?.filter(filterNotEmptyArrayItems).map(address => ({
      address: {
        ...address,
        isDefaultBillingAddress: address.isDefaultBillingAddress || false,
        isDefaultShippingAddress: address.isDefaultShippingAddress || false,
        phone: address.phone || undefined,
      },
      id: address?.id || "",
      onSelect: () => null,
    })) || [];
  return (
    <S.Wrapper>
      <section>
        <S.Title data-cy="checkoutPageSubtitle">{t("Billing Address")}</S.Title>
        {billingAsShippingPossible && (
          <Checkbox
            data-cy="checkoutPaymentBillingAsShippingCheckbox"
            name="billing-same-as-shipping"
            checked={billingAsShippingAddress}
            onChange={() =>
              setBillingAsShippingAddress(!billingAsShippingAddress)
            }
          >
              {t("Same as shipping address")}
          </Checkbox>
        )}
        {!billingAsShippingAddress && (
          <>
            {billingAsShippingPossible && <S.Divider />}
            {userAddresses ? (
              <AddressGridSelector
                formId={billingFormId}
                formRef={billingFormRef}
                addresses={adresses}
                selectedAddressId={selectedUserAddressId|| adresses.find((addr)=>addr.address.isDefaultBillingAddress)?.id}
                countriesOptions={countries?.filter(filterNotEmptyArrayItems)}
                userId={userId}
                errors={billingErrors}
                onSelect={(address, id) =>
                  setBillingAddress(address, undefined, id)
                }
                newAddressFormId={newAddressFormId}
              />
            ) : (
              <AddressForm
                formId={billingFormId}
                formRef={billingFormRef}
                countriesOptions={countries.filter(filterNotEmptyArrayItems)}
                address={checkoutBillingAddress || undefined}
                handleSubmit={address =>
                  setBillingAddress(address, address?.email)
                }
                includeEmail={!billingAsShippingPossible}
                errors={billingErrors}
              />
            )}
          </>
        )}
      </section>
      <S.OutOfSight>
          <S.Divider />
          <section>
          <S.Title data-cy="checkoutPageSubtitle"><Trans>PAYMENT METHOD</Trans></S.Title>
            <PaymentGatewaysList
              errors={gatewayErrors}
              paymentGateways={paymentGateways}
              formRef={gatewayFormRef}
              formId={gatewayFormId}
              processPayment={processPayment}
              selectedPaymentGateway={selectedPaymentGateway}
              selectedPaymentGatewayToken={selectedPaymentGatewayToken}
              selectPaymentGateway={selectPaymentGateway}
              onError={onGatewayError}
            />
          </section>
      </S.OutOfSight>
    </S.Wrapper>
  );
};

export { CheckoutPayment };
