import * as React from "react";
import {structuredData} from "../../core/SEO/Homepage/structuredData";
import {ProductsList_shop} from "./gqlTypes/ProductsList";
import "./scss/index.scss";


const Page: React.FC<{
    shop: ProductsList_shop;
}> = ({ shop}) => {
    return (
        <script className="structured-data-list" type="application/ld+json">
            {structuredData(shop)}
        </script>
    );
};

export default Page;
