import "./scss/index.scss";

import isEqual from "lodash/isEqual";
import * as React from "react";

import { ProductVariantPicker } from "@components/organisms";
import {useUserDetails} from "@sdk/react";
import { ICheckoutModelLine } from "@sdk/repository";
import { IProductVariantsAttributesSelectedValues, ITaxedMoney } from "@types";
import {Trans} from "react-i18next";
import {MoneyPer, TaxedMoney} from "../../@next/components/containers";
import {
  ProductDetails_product,
  ProductDetails_product_pricing,
  ProductDetails_product_variants,
  ProductDetails_product_variants_pricing,
  ProductDetails_product_variants_stocks
} from "../../views/Product/gqlTypes/ProductDetails";
import AddToCart from "./AddToCart";
import { QuantityTextField } from "./QuantityTextField";

const LOW_STOCK_QUANTITY = 5;
interface ProductDescriptionProps {
  productId: string;
  product: ProductDetails_product,
  productVariants: ProductDetails_product_variants[];
  name: string;
  pricing: ProductDetails_product_pricing;
  items: ICheckoutModelLine[];
  addToCart(varinatId: string, quantity?: number): void;
  setVariantId(variantId: string);
}

interface ProductDescriptionState {
  quantity: number;
  variant: string;
  variantStock: number;
  variantPricing: ProductDetails_product_variants_pricing;
  variantPricingRange: {
    min: ITaxedMoney;
    max: ITaxedMoney;
  };
}

const ProductStock: React.FC<{
    variantStocks: ProductDetails_product_variants_stocks[] | null,
}> = (
    {
        variantStocks,
    }
) => {
    return <div className="product-description__stocks">
        <h4><Trans>Stock</Trans></h4>
        <p>{variantStocks.filter(stock => !!stock.quantity)
            .sort((a, b) => a.warehouse.order > b.warehouse.order ? 1 : -1)
            .map((stock, i) => <span
            key={stock.warehouse.name}>
            {stock.warehouse.name.split(" ")[0]}: {stock.quantity > 20 ? "20+" : stock.quantity}{i < variantStocks.length - 1 && ", "}
        </span>)}</p>
    </div>
}

const ProductPrice : React.FC<    {
        variantPricing: ProductDetails_product_variants_pricing,
        product: ProductDetails_product,
    }> = (
    {
        variantPricing,
        product ,
    }
) => {
    const {data: user} = useUserDetails()
    const extraData  = product.metadata.filter((k) => k.key === "extra_data")[0]?.value
    let parsedExtraData : {weight?:string, volume?:string}
      try{
        parsedExtraData = JSON.parse(extraData)
      }
      catch (e){
        parsedExtraData = {}
      }
    const {
        weight,
        volume,
    } = parsedExtraData;

    if (variantPricing === null) {
        return null
    }
    return (
            <div className={"product-description__prices"}>
                {user && <div className={"product-description__prices_client"}>
                    <label><Trans>Client Price</Trans></label>
                    <MoneyPer taxedMoney={variantPricing.price} net={false} />
                    <div>
                        <MoneyPer taxedMoney={variantPricing.price} net={true} />
                        <MoneyPer taxedMoney={variantPricing.price} unitLabel={"Kg"} value={Number(weight)} />
                        <MoneyPer taxedMoney={variantPricing.price} unitLabel={"L"} value={Number(volume)} />
                    </div>
                </div>}
            <div className={"product-description__prices_retail"}>
                <label><Trans>Retail price</Trans></label>
                <MoneyPer taxedMoney={variantPricing.priceUndiscounted} net={false} />
                <div>
                    <MoneyPer taxedMoney={variantPricing.priceUndiscounted} net={true} />
                    <MoneyPer taxedMoney={variantPricing.priceUndiscounted} unitLabel={"Kg"} value={Number(weight)}/>
                    <MoneyPer taxedMoney={variantPricing.priceUndiscounted} unitLabel={"L"} value={Number(volume)}/>
                </div>
            </div>
            </div>
        );
  };



class ProductDescription extends React.Component<
  ProductDescriptionProps,
  ProductDescriptionState
> {
  constructor(props: ProductDescriptionProps) {
    super(props);

    this.state = {
      quantity: 1,
      variant: "",
      variantPricing: null,
      variantPricingRange: {
        max: props.pricing.priceRange.stop,
        min: props.pricing.priceRange.start,
      },
      variantStock: null,
    };
  }

  getProductPrice = () => {
    const { variantPricingRange, variantPricing } = this.state;

    const { min, max } = variantPricingRange;
    if (variantPricing) {
      if (isEqual(variantPricing.priceUndiscounted, variantPricing.price)) {
        return <TaxedMoney taxedMoney={variantPricing.price} />;
      } else {
        return (
          <>
            <span className="product-description__undiscounted_price">
              <TaxedMoney taxedMoney={variantPricing.priceUndiscounted} />
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <TaxedMoney taxedMoney={variantPricing.price} />
          </>
        );
      }
    }
    if (isEqual(min, max)) {
      return <TaxedMoney taxedMoney={min} />;
    } else {
      return (
        <>
          <TaxedMoney taxedMoney={min} /> - <TaxedMoney taxedMoney={max} />
        </>
      );
    }
  };

  onVariantPickerChange = (
    _selectedAttributesValues?: IProductVariantsAttributesSelectedValues,
    selectedVariant?: ProductDetails_product_variants
  ) => {
    if (selectedVariant) {
      this.setState({
        variant: selectedVariant.id,
        variantPricing: selectedVariant.pricing,
        variantStock: selectedVariant.quantityAvailable,
      });
      this.props.setVariantId(selectedVariant.id);
    } else {
      this.setState({ variant: "", variantPricing: null });
      this.props.setVariantId("");
    }
  };

  canAddToCart = () => {
    const { items } = this.props;
    const { variant, quantity, variantStock } = this.state;

    const cartItem = items?.find(item => item.variant.id === variant);
    const syncedQuantityWithCart = cartItem
      ? quantity + (cartItem?.quantity || 0)
      : quantity;
    return quantity !== 0 && variant && variantStock >= syncedQuantityWithCart;
  };

  handleSubmit = () => {
    this.props.addToCart(this.state.variant, this.state.quantity);
    this.setState({ quantity: 0 });
  };

  getAvailableQuantity = () => {
    const { items } = this.props;
    const { variant, variantStock } = this.state;

    const cartItem = items?.find(item => item.variant.id === variant);
    const quantityInCart = cartItem?.quantity || 0;
    return variantStock - quantityInCart;
  };

  handleQuantityChange = (quantity: number) => {
    this.setState({
      quantity,
    });
  };

  renderErrorMessage = (message: string, showContact) => {
      return (
          <div>
              <p className="product-description__error-message"><Trans>{message}</Trans>
                  {showContact &&<>
                      <br/>
                      <Trans>Ask for availability</Trans> <a href={`mailto:info@erimell.ee`}>info@erimell.ee</a>
                      </>}
              </p>

          </div>
      );
  };

  render() {
    const { name, product } = this.props;
    const { variant, variantStock, quantity, variantPricing} = this.state;
    const availableQuantity = this.getAvailableQuantity();
    const isOutOfStock = !!variant && variantStock <= 0;
    const isNoItemsAvailable = !!variant && !isOutOfStock && !availableQuantity;
    const isLowStock =
      !!variant &&
      !isOutOfStock &&
      !isNoItemsAvailable &&
      availableQuantity < LOW_STOCK_QUANTITY;
    const variantStocks = product.variants[0]?.stocks
    return (
      <div className="product-description">
        <h3>{name}</h3>
        <h4> <ProductPrice product={product} variantPricing={variantPricing}  /></h4>
        {isOutOfStock && this.renderErrorMessage("Products Out of stock", true)}
        {isLowStock && this.renderErrorMessage("Low stock", false)}
        {isNoItemsAvailable && this.renderErrorMessage("No items available", true)}
        { !isOutOfStock && !isNoItemsAvailable &&
            <>
        {variantStocks && <ProductStock variantStocks={variantStocks}/> }
        <div className="product-description__variant-picker">
          <ProductVariantPicker
            productVariants={this.props.productVariants}
            onChange={this.onVariantPickerChange}
            selectSidebar={true}
          />
        </div>
        <div className="product-description__quantity-input">
          <QuantityTextField
            quantity={quantity}
            maxQuantity={availableQuantity}
            disabled={isOutOfStock || isNoItemsAvailable}
            onQuantityChange={this.handleQuantityChange}
            hideErrors={!variant || isOutOfStock || isNoItemsAvailable}
          />
        </div>
        <AddToCart
          onSubmit={this.handleSubmit}
          disabled={!this.canAddToCart()}
        />
        </> }
      </div>
    );
  }
}

export default ProductDescription;
