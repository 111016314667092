import userImg from "../../images/user.svg";

import React from "react";
import {
  mediumScreen,
} from "../../globalStyles/scss/variables.scss";
import "./scss/index.scss";

import { useCart, useSignOut, useUserDetails } from "@sdk/react";

import Media from "react-media";
import { Link } from "react-router-dom";
import ReactSVG from "react-svg";

import {
  MenuDropdown,
  Offline,
  Online,
  OverlayContext,
  OverlayTheme,
  OverlayType,
} from "..";
import * as appPaths from "../../app/routes";
import { maybe } from "../../core/utils";
import { TypedMainMenuQuery } from "./queries";

import classNames from "classnames";
import {Trans, useTranslation} from "react-i18next";
import cartImg from "../../images/cart.svg";
import logoImg from "../../images/erimell_logo.png";
import hamburgerHoverImg from "../../images/hamburger-hover.svg";
import hamburgerImg from "../../images/hamburger.svg";

const MainMenu: React.FC = () => {
  const { data: user } = useUserDetails();
  const [signOut] = useSignOut();
  const { items } = useCart();

  const handleSignOut = () => {
    signOut();
  };
  const {t} = useTranslation()
  const cartItemsQuantity =
    (items &&
      items.reduce((prevVal, currVal) => prevVal + currVal.quantity, 0)) ||
    0;

  return (<>
    <header>
    <OverlayContext.Consumer>
      {overlayContext => (
        <nav className="main-menu" id="header" style={{height: "3.55rem"}}>
          <div className="main-menu__left">
            <TypedMainMenuQuery renderOnError displayLoader={false}>
              {({ data }) => {
                const items = maybe(() => data.shop.navigation.main.items, []);

                return (
                  <ul>
                    <Media
                      query={{ maxWidth: mediumScreen }}
                      render={() => (
                        <li
                          className="main-menu__hamburger"
                          onClick={() =>
                            overlayContext.show(
                              OverlayType.sideNav,
                              OverlayTheme.left,
                              { data: items }
                            )
                          }
                        >
                          <ReactSVG
                            path={hamburgerImg}
                            className={"main-menu__hamburger--icon"}
                          />
                          <ReactSVG
                            path={hamburgerHoverImg}
                            className={"main-menu__hamburger--hover"}
                          />
                        </li>
                      )}
                    />
                  </ul>
                );
              }}
            </TypedMainMenuQuery>
          </div>

          <div className="main-menu__center">
            <Link to={appPaths.baseUrl}>
              <img src={logoImg} alt={"Erimell"} style={{maxHeight:"min(100%, 5ch)"}}/>
            </Link>
          </div>

          <div className="main-menu__right">
            <ul>
              <Online>
                  <Media
                      query={{minWidth: mediumScreen}}
                  >{(matches) => {
                      return matches ? (
                              <>
                                  {user ? (<>
                                          <MenuDropdown
                                              head={
                                                  <li className="main-menu__button main-menu__button__secondary spacer">
                                                  <span
                                                      className={"underlined"}><Trans>My account</Trans>:</span> {user.email}

                                                  </li>}
                                              content={
                                                  <ul className="main-menu__dropdown">
                                                      <Link to={appPaths.accountUrl}>
                                                          <li data-testid="my_account__link">
                                                              {t("My account")}
                                                          </li>
                                                      </Link>
                                                      <Link to={appPaths.orderHistoryUrl}>
                                                          <li data-testid="order_history__link">

                                                              {t("Order History")}
                                                          </li>
                                                      </Link>

                                                      <Link to={appPaths.addressBookUrl}>
                                                          <li data-testid="address_book__link">
                                                              {t("Address Book")}
                                                          </li>
                                                      </Link>
                                                      <li
                                                          onClick={handleSignOut}
                                                          data-testid="logout-link"
                                                      >
                                                          {t("Log out")}
                                                      </li>
                                                  </ul>
                                              }
                                          />
                                          <li className="main-menu__button">
                                              <Link to={"/cart/"}>
                                                  <Trans>Checkout</Trans>
                                              </Link>
                                          </li>
                                      </>
                                  ) : (
                                      <>
                                          <li
                                              data-testid="login-btn "
                                              className="main-menu__button"
                                              onClick={() =>
                                                  overlayContext.show(
                                                      OverlayType.login,
                                                      OverlayTheme.modal
                                                  )
                                              }
                                          >
                                              <Trans>Log in</Trans>
                                          </li>
                                          <span className={"spacer"} />
                                          <li className={"main-menu__button main-menu__button__secondary spacer"}>
                                              <a href={"https://erimell.ee/tellimiskeskkond"}>
                                                  <Trans>Sign up</Trans>
                                              </a>
                                          </li>
                                      </>
                                  )}
                              </>
                          ) :
                          <li><Link
                              className={classNames("main-menu__user main-menu__icon", {'main-menu__user--active': !!user})}
                              onClick={(event) => {
                                  if (!user) {
                                      event.stopPropagation()
                                      event.preventDefault()
                                      overlayContext.show(
                                          OverlayType.login,
                                          OverlayTheme.modal
                                      )
                                  }
                              }
                              }
                              to={appPaths.accountUrl}>
                              <ReactSVG path={userImg} />
                          </Link>
                          </li>
                  }}
                </Media>
                <li
                  className="main-menu__icon main-menu__cart"
                  onClick={() => {
                    overlayContext.show(OverlayType.cart, OverlayTheme.right);
                  }}
                >
                  <ReactSVG path={cartImg} />
                  {cartItemsQuantity > 0 ? (
                    <span className="main-menu__cart__quantity">
                      {cartItemsQuantity}
                    </span>
                  ) : null}
                </li>
              </Online>
              <Offline>
                <li className="main-menu__offline">
                  <Media
                    query={{ minWidth: mediumScreen }}
                    render={() => <span>Offline</span>}
                  />
                </li>
              </Offline>
            </ul>
          </div>
        </nav>
      )}
    </OverlayContext.Consumer>
   </header>
   <div className="print__center">
      <img src={logoImg} alt={"Erimell"}/>
    </div>
    </>
  );
};

export default MainMenu;
