import gql from "graphql-tag";

import {TypedQuery} from "../../core/queries";
import {
    basicProductFragment,
    productPricingFragment,
} from "../Product/queries";
import {Category, CategoryVariables} from "./gqlTypes/Category";


export const categoryProductVariantFragment = gql`
  fragment CategoryProductVariantFields on ProductVariant {
    id
    sku
    name
    quantityAvailable: globalQuantityAvailable
    stocks @include(if: $logged_in) {
      quantity
      warehouse {
        slug
        name
        order
      }
    }
  }
`;

 const categoryProductsQuery = gql`
  ${basicProductFragment}
  ${productPricingFragment}
  ${categoryProductVariantFragment}
  query CategoryCollection(
    $category_id: ID
    $collection_id: ID
    $with_category: Boolean!
    $with_collection: Boolean!
    $logged_in: Boolean!
    $after: String
    $pageSize: Int
    $sortBy: ProductOrder
    $products_filter: ProductFilterInput
    # We're not filtering based on attr atm,
    # $attributes_filter: AttributeFilterInput
  ) {
    products(
      after: $after
      first: $pageSize
      sortBy: $sortBy
      filter: $products_filter
    ) {
      totalCount
      edges {
        node {
          ...BasicProductFields
          descriptionJson
          ...ProductPricingField
          category {
            id
            name
          }
          images {
              url
          }
          collections {
              id
              name
              slug
          }
          variants {
            ...CategoryProductVariantFields
          }
          metadata (sortKeys: false) {
            key
            value
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
    collection(id: $collection_id) @include(if: $with_collection) {
      id
      slug
      name
      seoDescription
      seoTitle
      backgroundImage {
        url
      }
    }
    category(id: $category_id)  @include(if: $with_category){
      seoDescription
      seoTitle
      id
      name
      backgroundImage {
        url
      }
      ancestors(last: 5) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
    # Not being used. TODO: refactory this query into two, category + attr stuff, then products.
    # attributes(filter: $attributes_filter, first: 100) {
    #   edges {
    #     node {
    #       id
    #       name
    #       slug
    #       values {
    #         id
    #         name
    #         slug
    #       }
    #     }
    #   }
    # }
  }
`;

export const TypedCategoryProductsQuery = TypedQuery<Category, CategoryVariables>(categoryProductsQuery);
