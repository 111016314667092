import React from "react";

import {useTranslation} from "react-i18next";
import * as S from "./styles";
import { IProps } from "./types";

/**
 * Cart footer to use with conjunction of cart rows
 */
const CartFooter: React.FC<IProps> = ({
  subtotalPrice,
  shippingPrice,
  discountPrice,
  vatPrice,
  totalPrice,
}: IProps) => {
  const {t} = useTranslation();
  return (
    <S.Wrapper showShipping={!!shippingPrice} showDiscount={!!discountPrice}>
      <S.SubtotalText>{t("Subtotal")}</S.SubtotalText>
      <S.SubtotalPrice>{subtotalPrice}</S.SubtotalPrice>
      <S.VatpriceText>{t("VAT")}</S.VatpriceText>
      <S.Vatprice>{vatPrice}</S.Vatprice>

      {shippingPrice && (
        <>
          <S.ShippingText>{t("Shipping")}</S.ShippingText>
          <S.ShippingPrice>{shippingPrice}</S.ShippingPrice>
        </>
      )}
      {discountPrice && (
        <>
          <S.DiscountText>Promo Code</S.DiscountText>
          <S.DiscountPrice>{discountPrice}</S.DiscountPrice>
        </>
      )}
      <S.TotalText>{t("Order total")}</S.TotalText>
      <S.TotalPrice>{totalPrice}</S.TotalPrice>
    </S.Wrapper>
  );
};

export { CartFooter };
