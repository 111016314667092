import 'bootstrap/dist/css/bootstrap.min.css';
import "../globalStyles/scss/bootstrap_override.scss";
import "../globalStyles/scss/index.scss";

import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';

import React from "react";

import { Footer, MainMenu, MetaConsumer, OverlayManager } from "../components";
import {setupI18Next} from "../i18n/i18n";
import { Routes } from "./routes";

const App: React.FC = () => {
   setupI18Next("et")
  return (
    <>
      <OverlayManager />
      <MetaConsumer />
      <MainMenu />
      <Routes />
      <Footer />
    </>
  );
};

export default App;

