import {media, styled} from "@styles";

export const DataTableDiv = styled.div`
  strong {
    font-weight: bold;
  }
  table td img {
    margin-right: unset;
  }
  color: rgba(0, 0, 0, 0.8);
     ${media.tableBreak`
      table td img {
        height: 5rem;
      }
  `}
`

export const Stocks = styled.div`
 display: grid;
 grid-template-columns: auto auto;
 width: fit-content;
 margin: auto;
 align-items: center;
 white-space: nowrap;
 color: rgba(0, 0, 0, 0.5);

 .fullname{
    display: none;
 }
 .separator{
    display: none;
 }

 small {
 font-size: 10px;
 }
 ${media.tableBreak`
    margin-right: 0;
    grid-template-columns: auto;
    place-items: end;
    .fullname{
        display: inline;
    }
    .shortname, .no-mobile{
        display: none;
    }
    .separator {
        display: inline;
    }

 `}
`

export const AddToCart = styled.div`
    display: flex;
    gap: 1ch;
    width: 15ch;
    position: relative;
    justify-items: center;
    align-content: center;

    button.cart-button {
        width: 2em;
        border: 0 grey solid;
        &.l {
            border-right-width: 1px;
        }
        &.r {
            border-left-width: 1px
        }
        background-color: ghostwhite;
        transition: all 100ms ease-in-out;
        height: 100%;

        &:hover {
            background-color: #f3f3f8;

        }

        &:active {
            background-color: #e6e6ec;
        }

        &:disabled {
            background-color: transparent;
            color: transparent;
            border-color: transparent;

        }

    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button,
    input[type=number] {
        -webkit-appearance: none;
        margin: 0;
        -moz-appearance: textfield;
    }

    input {
        text-align: center;
        padding-left: 0;
    }

    .text_input {
        display: grid;
        align-items: center;

        label {
            left: -1.6em;
        }

        margin-bottom: unset;
    }

    div.trash {
        display: flex;
        align-items: center;

        &.active .trash-icon {
            opacity: 100;
        }

        .trash-icon {
            transition: opacity 100ms, color 100ms;
            opacity: 0;
            font-size: large;
            cursor: pointer;
            margin-right: 5px;
            color: grey;

            &:hover{
                color: #757272;
            }

            &:active {
                color: #605f5f;
            }

        }
    }
`

export const TireSize = styled.span`
    font-size: 15px;
    white-space: nowrap;
    font-weight: 500;
`

export const ProductName = styled.div`
    font-size: 12px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.5);
    z-index: 1;
`

export const Name = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    padding: .3em 0;
    color: rgba(0, 0, 0, 0.8);
    max-width: calc(100vw - 140px);
`

export const Brand = styled.span`


    `

export const Model = styled.span`


    `
export const Price = styled.span`
display: flex;
flex-direction: column;
white-space: nowrap;
gap: .5ch;
font-size: 18px;
line-height: 17px;
.netmoney{
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.5);
    ::after{
     content: "+KM";
     font-size: 10px;
     }
    &.perKg::after{
      content: "+KM/Kg";
    }
    &.perL::after{
      content: "+KM/L";
    }
}
    `

export const SkuBrandModel = styled.div`
display: grid;
grid-template-columns: 18ch 22ch 24ch;
${media.tableBreak`
    grid-template-columns: 1fr;
 `}
${media.smallScreen`
    padding-top: 1rem;
`
}
grid-template-rows: 1fr;

`

export const FlexCenter = styled.div`
display: flex;
justify-content: center;
`

export const Arrow = styled.span`
`

export const TagLinks = styled.div`
display: flex;
font-weight: bold;
flex-wrap: wrap;
column-gap: 1ch;
a {
   text-decoration: underline;
}
`


export const  TireRatings = styled.div`
display: grid;
grid-template-columns: 5ch 9ch 5ch 5ch 1fr;
column-gap: 2px;
${media.tableBreak`
    grid-template-columns: 1fr;
 `}
grid-template-rows: 1fr;
`

export const TireRatingAttr = styled.span`
white-space: nowrap;
div{
    display: inline;
}
svg {
aspect-ratio: 1/1;
height: 1rem;
}

`

export const SizeCell = styled.div`
display:flex;
flex-direction: column;
gap: .5ch;
`

export const  Size = styled.div`
::before{
 content: "Suurus: "
}
`
export const  Pc = styled.div`
::before{
 content: "Kastis: "
}
`
export const  Weight = styled.div`
::after{
 content: "Kg"
}
`
export const  Volume = styled.div`
::after{
 content: "L"
}
`
export const  Sku = styled.span``
