import React from "react";

import { Chip, DropdownSelect } from "@components/atoms";

import {DebounceChange} from "@temp/components";
import {useTranslation} from "react-i18next";
import * as S from "./styles";
import { IProps } from "./types";

export const ProductListHeader: React.FC<IProps> = ({
  numberOfProducts = 0,
  // openFiltersMenu,
  // clearFilters,
  activeSortOption,
  // activeFilters = 0,
  activeFiltersAttributes = [],
  sortOptions,
  onChange,
  onCloseFilterAttribute,
  search,
  setSearch,
}: IProps) => {
  const { t } = useTranslation();
    return <S.Wrapper>
        {setSearch &&
        <DebounceChange
            debounce={evt =>
                setSearch((evt.target.value as string).toLowerCase())
            }
            value={search}
            time={200}
        >
            {({change, value}) =>
                <div className="input-group py-1">
                          <span className="input-group-text" id="basic-addon1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-search"
                                 viewBox="0 0 16 16">
                                <path
                                    d={"M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85" +
                                     "3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5" +
                                    " 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"}/>
                            </svg>
                          </span>
                    <input type="text" className="form-control" placeholder="Otsi..." aria-label="Otsi..."
                           aria-describedby="basic-addon1" onChange={change} value={value || ""} />
                </div>
            }
        </DebounceChange>}
        <S.Bar>
        <S.LeftSide>
          {/* HIDDEN BY CLIENT REQUEST, SEARCHING BY TEXT INPUT MORE FUNCTIONAL */}
          {/*<S.FiltersButton onClick={openFiltersMenu} data-cy="filters__button">*/}
          {/*  <Icon name="filter" size={24} />*/}
          {/*  <S.Filters>*/}
          {/*    FILTERS{" "}*/}
          {/*    {activeFilters > 0 && (*/}
          {/*      <>*/}
          {/*        <span>({activeFilters})</span>*/}
          {/*      </>*/}
          {/*    )}*/}
          {/*  </S.Filters>*/}
          {/*</S.FiltersButton>*/}
          {/*{activeFilters > 0 && (*/}
          {/*  <S.Clear onClick={clearFilters}>CLEAR FILTERS</S.Clear>*/}
          {/*)}*/}

          <S.Element data-cy="no-of-products-found_label">
            <S.Label>{t("Products found")}: </S.Label>
            {numberOfProducts}
          </S.Element>
        </S.LeftSide>

        <S.RightSide>
          <S.Element>
            <S.Sort>
              <DropdownSelect
                onChange={onChange}
                options={sortOptions}
                value={sortOptions.find(
                  option => option.value === activeSortOption
                )}
              />
            </S.Sort>
          </S.Element>
        </S.RightSide>
      </S.Bar>
      <S.FiltersChipsWrapper>
        {activeFiltersAttributes.map(
          ({ attributeSlug, valueName, valueSlug }) => (
            <Chip
              onClose={() => onCloseFilterAttribute(attributeSlug, valueSlug)}
            >
              {valueName}
            </Chip>
          )
        )}
      </S.FiltersChipsWrapper>
    </S.Wrapper>
};
