import "./scss/index.scss";

import * as React from "react";

import { Button, Form, TextField } from "..";
import { maybe } from "../../core/utils";
import { TypedPasswordResetMutation } from "./queries";

import {Trans, useTranslation} from "react-i18next";
import { passwordResetUrl } from "../../app/routes";

const PasswordResetForm: React.FC = () => {
    const {t} = useTranslation()
    return <div className="password-reset-form">
        <p>
            {t("Please provide us your email address so we can share you a link to reset your password")}
        </p>
        <TypedPasswordResetMutation>
            {(passwordReset, {loading, data}) => {
                const submitSuccess = data?.requestPasswordReset?.errors.length === 0;
                return (
                    <Form
                        errors={maybe(() => data.requestPasswordReset.errors, [])}
                        onSubmit={(event, {email}) => {
                            event.preventDefault();
                            passwordReset({
                                variables: {
                                    email,
                                    redirectUrl: `${window.location.origin}${passwordResetUrl}`,
                                },
                            });
                        }}
                    >
                        <TextField
                            name="email"
                            autoComplete="email"
                            label={t("Email Address")}
                            type="email"
                            required
                        />
                        <div className="password-reset-form__button">
                            {submitSuccess && <div><Trans>Reset email sent, check you email for reset link</Trans></div>}
                            <Button type="submit" {...((loading || submitSuccess) && {disabled: true})}>
                                {loading ? t("Loading") : t("Reset password")}
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </TypedPasswordResetMutation>
    </div>
};

export default PasswordResetForm;
