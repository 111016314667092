
// simple way to not have to copy the translation key all the time...

// key, ET, EN?,
// If no en, defaults to the key

export const translations = [
    ["Price", "Hind"],
    ["VAT-disclamer",
        "Käesoleva tellimuse kinnitus saadetakse teie kontoga seotud e-posti aadressile ({email}), mis on toodud arve ja tarne aadressi juures. Tellimuse kinnitusel olev lõppsumma võib vähesel määral erineda arvel olevast lõppsummast (tulenevalt KM arvutuskäigust). Arve saadetakse teie e-mailile pärast tellimuse töötlemist ja/või pannakse kaubaga kaasa (nii nagu on teie eelistus tavapäraselt olnud).",
        "Order confirmation for this order will been sent to your e-mail address ({email}). Total sum on the confirmation may be slightly different compared to the total on the invoice (due to the exact calculation of VAT). " +
        "Invoice will be sent to your e-mail after the order has been processed and/or will be provided with the goods (according to your usual preference).", ],
    ["Attention", "Tähelepanu"],
    ["Out of stock, ask", "Hetkel otsas, küsi"],
    ["Ask for availability", "Küsi saadavust"],
    ["Products Out of stock", "Toode on hetkel otsas"],
    ["Low stock", "Madal laoseis"],
    ["No items available", "Toode pole saadaval"],
    ["Please confirm your order at the bottom of the page", "Palun kinnitage tellimus lehe alaosas."],
    ["Your Cart", "Ostukorv"],
    ["My Cart", "Sinu ostukorv"],
    ["looks empty", "on hetkel tühi"],
    ["Your Cart looks empty", "Ostukorv on hetkel tühi"],
    ["Maybe you haven’t made your choices yet", "Palun lisa soovitud tooted ostukorvi"],
    ["BAG EMPTY_TEXT", "Te pole oma ostukorvi midagi lisanud. Oleme kindlad, et leiate midagi meie poest", "You haven’t added anything to your bag. We’re sure you’ll find something in our store",],
    ["Continue shopping", "Tagasi poodi"],
    ["Products", "Tooted"],
    ["Images", "Pildid"],
    ["Price", "Hind"],
    ["Client Price", "Kliendihind"],
    ["Retail price", "Jaehind"],
    ["Quantity", "Kogus"],
    ["Add to basket", "Lisa ostukorvi"],
    ["Maximum quantity is", "Maksimaalne kogus on"],
    ["Total Price", "Summa"],
    ["Total", "Summa"],
    ["VAT excluded", "KM-ta"],
    ["Total VAT excluded", "KM-ta summa"],
    ["Order total", "Tellimuse summa"],
    ["VAT", "Käibemaks"],
    ["Shipping Remark", "Tarnet puudutavad märkused"],
    ["Billing Address", "Rekvisiidid arvel"],
    ["Same as shipping address", "Rekvisiidid arvel on samad, mis kauba tarne vaates (eelnevalt juba täidetud)."],
    ["Shipping Address", "Kauba tarne aadress"],
    ["Default Address", "Vaikimisi aadress"],
    ["Default Shipping Address", "Vaikimisi tarneaadress"],
    ["Default Billing Address", "Vaikimisi arve aadress"],
    ["Set as default billing address", "Määra vaikimisi arve aadressiks"],
    ["Set as default shipping address", "Määra vaikimisi tarneaadressiks"],
    ["Add new address", "Lisa uus aadress"],
    ["Edit address", "Muuda aadressi"],
    ["Payment Method", "Makseviis"],
    ["By invoice", "Arvega"],
    ["Proceed to checkout", "Jätka tellimuse vormistamist"],
    ["Continue to Payment", "Täida maksmisega seotud andmed"],
    ["Continue to Shipping", "Täida tarneviisiga seotud andmed"],
    ["Continue to Review", "Jätka tellimuse lõplikku ülevaatamist"],
    ["Address", "Tarne aadress"],
    ["Shipping", "Tarneviis"],
    ["Shipping Method", "Tarneviis"],
    ["Delivery Cost", "Saatekulu"],
    ["Payment", "Makseviis"],
    ["Review", "Ülevaade"],
    ["Size", "Suurus"],
    ["Added", "Lisatud"],
    ["Description", "Kirjeldus"],
    ["Attributes", "Omadused"],
    ["Other products in this category", "Sarnased tooted selles kategoorias"],
    ["Checkout", "Vormista tellimus"],
    ["MY BAG", "Ostukorv"],
    ["ITEMS", "toodet"],
    ["Qty", "Kogus"],
    ["Subtotal", "Summa"],
    ["Checkout", "Vormista tellimus"],
    ["Log in", "Logi sisse"],
    ["Sign up", "Registreeru"],
    ["Stock", "Laos"],
    ["Buy", "Kogus"],
    ["Cart", "Ostukorv"],
    ["Home", "Avaleht"],
    ["Back", "Tagasi"],
    ["My account", "Minu konto"],
    ["My Data", "Minu Andmed"],
    ["Order History", "Tellimuste ajalugu"],
    ["Category", "Kategooria"],
    ["Tags", "Sildid"],
    ["My account", "Minu konto"],
    ["My Data", "Minu Andmed"],
    ["Personal details", "Isiklikud andmed "],
    ["Address Book", "Minu aadressid"],
    ["Log out", "Logi välja"],
    ["Qty", "Kogus"],
    ["Products found", "Leitud tooteid"],
    ["More", "Veel"],
    ["pc", "tk"],
    ["To update the application to the latest version, please refresh the page!", "Rakenduse värskendamiseks värskendage palun lehte!"],
    ["New version is available!", "Uus versioon on saadaval!"],
    ["Refresh", "Värskenda"],
    ["Add", "Lisa"],
    ["Save", "Salvesta"],
    ["Cancel", "Tühista"],
    ["You are now logged out", "Olete nüüd välja logitud"],
    ["You are now logged in", "Olete nüüd sisse logitud"],
    ["An item has been added to your cart", "Toode lisatud ostukorvi"],
    ["An item has been removed from your cart", "Toode eemaldatud ostukorvist"],
    ["Shopping cart updated", "Ostukorv uuendatud"],
    ["Error has occurred", "Ilmnes viga"],
    ["Refresh the page and try again later", "Värskendage lehte ja proovige hiljem uuesti"],
    ["Erimell account", "Erimelli kasutajakonto"],
    ["Account", "Konto"],
    ["Sign in to account", "Logi sisse"],
    ["Sign in", "Logi sisse"],
    ["Email Address", "E-maili aadress"],
    ["My Password", "Minu Parool"],
    ["Password", "Parool"],
    ["Loading", "Laadimine"],
    ["Have you forgotten your password", "Kas oled salasõna unustanud"],
    ["Click here", "Kliki siia"],
    ["Reset your password", "Telli uus salasõna"],
    ["Please provide us your email address so we can share you a link to reset your password", "Palun sisestage enda kasutajakontoga seotud e-maili aadress. Saadame sellele lingi, mille kaudu saate kontole uue parooli teha."],
    ["Reset password", "Telli uus salasõna"],
    ["REVIEW ORDER", "TELLIMUSE ÜLEVAADE"],
    ["Thank you", "Täname"],
    ["for your order!", "tellimuse eest!"],
    ["Your order number is", "Teie tellimuse number on"],
    ["Order number", "Tellimuse number"],
    ["We've emailed you an order confirmation, and we'll notify you the when order has been shipped", "Tellimuse kinnitus on saadetud teie kontoga seotud e-maili aadressile. Kui tellimusega seoses tekib vajadus midagi täpsustada, siis võtame teiega kindlasti ühendust."],
    ["Your order nr: ", "Teie tellimuse nr: "],
    ["Go back to Order History", "Tagasi tellimuste ajaloo vaatesse"],
    ["CONTINUE SHOPPING", "Alusta uut tellimust"],
    ["ORDER DETAILS", "Tellimuse ülevaade"],
    ["Not charged", "Maksmata"],
    ["Fully charged", "Makstud"],
    ["In Cart", "Ostukorvis"],
    ["Unfulfilled", "Täitmata"],
    ["Fulfilled", "Täidetud"],
    ["Status", "Staatus"],
    ["Date of Order", "Tellimuse kuupäev"],
    ["Search term", "Otsing"],
    ["Sort by", "Sorteeri"],
    ["Place Order", "Kinnitage Tellimus"],
    ["Cart Summary", "Ostukorv"],
    ["First Name", "Eesnimi"],
    ["Last Name", "Perekonnanimi"],
    ["Company Name (Optional)", "Ettevõtte nimi (valikuline)"],
    ["Phone", "Telefon"],
    ["Phone number", "Telefon"],
    ["Address line 1", "Aadressirida 1"],
    ["Address line 2", "Aadressi rida 2"],
    ["City", "Linn"],
    ["ZIP/Postal Code", "Postiindeks"],
    ["Country", "Riik"],
    ["State/province", "Maakond"],
    ["Qty", "Kogus"],
    ["Old Password", "Vana salasõna"],
    ["New Password", "Uus salasõna"],
    ["Confirm Password", "Kinnita salasõna"],
    ["Clear", "Tühista"],
    ["Price Low-High", "Hind alates odavamast"],
    ["Price High-Low", "Hind alates kallimast"],
    ["Name Increasing", "Tähestiku järgi A-Ü"],
    ["Name Decreasing", "Tähestiku järgi Ü-A"],
    ["Last updated Ascending", "Viimati uuendatud enne"],
    ["Last updated Descending", "Vanemad enne"],
    ["Please choose payment method", "Palun valige makseviis"],
    ["Please provide billing address.", "Palun märkige ära arve aadress."],
    ["Please provide shipping address.", "Palun märkige ära tarneaadress."],
    ["Reset email sent, check you email for reset link", "Seadistage uus salasõna e-maili aadressile saadetava lingi kaudu."],
    ["Canceled", "Tühistatud"],
    ["Warehouse Legend", "Laoseisu legend",],
    ["Passwords do not match", "Sisestatud salasõnad ei ühti"],
    ["Password is too short!" , "Salasõna on liiga lühike!"],
    ["Please retype password" , "Palun trüki salasõna uuesti"],
    ["Retyped password does not match" , "Uuesti trükitud salasõna ei ühti"],
    ["Old password isn't valid" , "Vana salasõna ei kehti"],
    ["Please enter an email address" , "Palun sisesta e-posti aadress"],
    ["Please fill in this field." , "Palun täida ära see väli"],
    ["Required field" , "Kohustuslik väli"],
    ["Please provide new password" , "Palun sisesta uus salasõna"],
    ["Password" , "Salasõna"],
    ["Retype password" , "Trüki salasõna uuesti"],
    ["Set new password" , "Seadista uus salasõna"],
    ["It seems that token for password reset is not valid anymore", "Parooli uuendamise link on aegunud."],
    ["Other Products", "Muud Tooted"]
]
