import {Button, Loader, RichTextContent} from "@components/atoms";
import {Money, MoneyPer} from "@components/containers";
import {Thumbnail} from "@components/molecules";
import React, {useState} from "react";
import ReactSVG from "react-svg";
import {SRLWrapper} from "simple-react-lightbox";

import {IProps} from "./types";

import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';

import {generateCategoryCollectionUrl, generateCategoryUrl, generateCollectionUrl, generateProductUrl} from "@temp/core/utils";
import {Trans, useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {
    Category_products_edges_node_parsed_MetaData as Product_node,
    Stock
} from "../../../../views/Category/gqlTypes/Category";

import {useCart, useUserDetails} from "@sdk/react";
import {OverlayContext, OverlayContextInterface, OverlayTheme, OverlayType} from "@temp/components";
import {QuantityTextField} from "@temp/components/ProductDescription/QuantityTextField";

import {tableBreak} from "@styles/constants";
import fuelIcon from "images/tire-icon-fuel.svg";
import noiseIcon from "images/tire-icon-noise.svg";
import wetIcon from "images/tire-icon-wet.svg";
import {AlertManager, useAlert} from "react-alert";
import {useDebouncedCallback} from 'use-debounce';
import "./primestyles.scss"
import * as S from "./styles";

export function ParseExtraValues(p: Product_node){
                const extraData = p.metadata.filter((k) => k.key === "extra_data")[0]?.value;
                let parsedData;
                try {
                    parsedData = extraData ? JSON.parse(extraData) : undefined
                } catch (e) {
                    parsedData = undefined;
                }
                return {
                    ...p,
                    ...(parsedData ? {extra_data: parsedData} : {}),

            }
}

const LightboxProductImage: React.FC<{ product: Product_node }> = ({product, children}) => {
    const fullImage = product.images?.length && product.images[0]?.url
    return fullImage ? <SRLWrapper options={{
        buttons: {
            showAutoplayButton: false,
            showDownloadButton: false,
            showNextButton: false,
            showPrevButton: false,
            showThumbnailsButton: false,
        },
        thumbnails: {showThumbnails: false},

    }}>
        <a href={fullImage}>
            {children}
        </a>
    </SRLWrapper> : <>{children}</> // ts only happy if children wrapped in fragment...

}

const ForceLogin: React.FC<{ user: any }> = ({children, user}) => {
    return <OverlayContext.Consumer>
        {(overlayContext: OverlayContextInterface) => (
            <div onClick={() => {
                // Make anons login
                if (!user) {
                    overlayContext.show(
                        OverlayType.login,
                        OverlayTheme.modal
                    );
                }
            }}>
                {children}
            </div>

        )}
    </OverlayContext.Consumer>

}
const showMessage = (alert: AlertManager, fail: boolean, title: string, content?:string) => {
    return () => (alert.show(
        {
            content,
            title,
        },
        {type: fail? "error": "success"}
    ))
}

// @ts-ignore // user, loaded ,updateItem, items, addItem, removeItem are implicitly any type.
// Too much hassle to redefine / drag and drop ts types at this time.
const ProductAddToCart: React.FC<{ product: Product_node }> = ({product, user, loaded, updateItem, items, addItem, removeItem}) => {
    /*
     A very hacky solution to a hard async problem of keeping the selected amount in sync with the backend and not have the value go nuts when altering the value
     */
    const alert = useAlert()
    const variant = product.variants[0];
    const {quantityAvailable} = variant;
    const cartItem = items?.find((item: { variant: { id: string; }; }) => item.variant.id === variant.id);
    const [purchaceQuantity, setPurchaceQuantity] = useState(cartItem?.quantity || 0)
    const [editing, setEditing] = useState(false)
    const {t} = useTranslation()
    const onError = showMessage(alert, true, t("Error has occurred"), t("Refresh the page and try again later"))
    const remove = () => removeItem(variant.id, showMessage(alert, false, t("An item has been removed from your cart")), onError)
    const modifyCart = () => {

        if (!user) {
            setEditing(false);
            return null
        }

        if (cartItem?.quantity && purchaceQuantity === 0) {
            remove()
        } else if (!cartItem?.quantity && !!purchaceQuantity) {
            addItem(variant.id, purchaceQuantity, showMessage(alert, false, t("An item has been added to your cart") ), onError)
        } else if (!!cartItem?.quantity && !!purchaceQuantity) {
            updateItem(variant.id, purchaceQuantity, showMessage(alert, false, t("Shopping cart updated")),onError)
        }
        setEditing(false)
    }

    if (cartItem?.quantity !== undefined && !editing && cartItem.quantity !== purchaceQuantity) {
        // Is being set from a cart update
        setPurchaceQuantity(cartItem.quantity)
    }
    if (cartItem?.quantity === undefined && !editing && !!purchaceQuantity) {
        setPurchaceQuantity(0)
    }



    const [debounceModifyCart] = useDebouncedCallback(modifyCart, 500);
    const onQuantityChange =(n: number) => {
                        n = Math.min(n, quantityAvailable)
                        debounceModifyCart()
                        setEditing(true)
                        setPurchaceQuantity(n)
                    }
    const disabled = user && !loaded || quantityAvailable === 0
    return <ForceLogin user={user}>
        <S.AddToCart>
            <QuantityTextField
                    quantity={loaded ? purchaceQuantity : 0}
                    maxQuantity={quantityAvailable}
                    // don't disable if no user, if disabled onClick event is lost
                    disabled={disabled}
                    onQuantityChange={onQuantityChange}
                    hideErrors={true}
                    label={"In Cart"}
                    contentRight={<button className={"cart-button r"} disabled={disabled} onClick={() => onQuantityChange(Math.min(purchaceQuantity+1, quantityAvailable))}> + </button>
            }
                    contentLeft={<button className={"cart-button l"} disabled={disabled} onClick={() => onQuantityChange(Math.max(purchaceQuantity-1, 0))}> - </button>}
                />

            <div className={"trash "+ (cartItem?.quantity ? "active": "")}>
                <button className={"trash-icon"} onClick={(e) => {
                    setPurchaceQuantity(0)

                remove()
            }}> <i   className={"trash-icon pi pi-trash"} /></button></div>

        </S.AddToCart>
    </ForceLogin>
}


const ProductDetailLink: React.FC<{ product: Product_node }> = ({product, children}) => {
    return <Link
        to={generateProductUrl(product.id, product.name)}
        key={product.id}
    >{children}
    </Link>
}

export const ProductTable: React.FC<IProps> = ({
                                                   products,
                                                   canLoadMore = false,
                                                   loading = false,
                                                   isTires,
                                                   onLoadMore = () => null,
                                               }: IProps) => {
    const {t} = useTranslation();
    const Expander = (product: Product_node, props: any) => {
        return product.descriptionJson !== "{}" && props.expander.element
    }

    const ProductExpansion = (product: Product_node) => {
        return <RichTextContent descriptionJson={product.descriptionJson} />
    }

    const Tooltips = () =>
        <>
            <Tooltip className="erml-tooltip" target=".stockheader" position={'bottom'}>
                <div>
                    <h3><Trans>Warehouse Legend</Trans></h3>
                    <p>T - <strong>Tartu</strong></p>
                    <p>R - <strong>Rakvere</strong></p>
                    <p>S - <strong>Saue</strong></p>
                    <p>P - <strong>Pärnu</strong></p>
                </div>
            </Tooltip>
            <Tooltip className="erml-tooltip" target=".nostock" autoHide={false} hideDelay={40} position="bottom">
                <div>
                    <Trans>Hetkel otsas, küsi</Trans> <a href={"mailto:info@erimell.ee"}>info@erimell.ee</a>
                </div>
            </Tooltip>
        </>


    const Sizing = (product: Product_node) => {
        // TODO Refactor...

        // is a tire
        if (product.extra_data?.attributes) {
            const {
                width,
                height,
                diameter,
            } = product.extra_data.attributes
            return <S.TireSize>
                {`${width}/${height}${diameter}`}
            </S.TireSize>
        }
        // Is a product
        const {
            size,
            weight,
            volume,
        } = product.extra_data || {};

        const taxedMoney = product.pricing?.priceRange?.start
        // A product only has either weight or volume
        return <S.SizeCell>
            {!!size && <S.Size>{size}</S.Size>}
            {/* Lots of checks to keep TS happy */}
            {!!weight && <> <S.Weight>{weight}</S.Weight> <MoneyPer taxedMoney={taxedMoney} unitLabel={"Kg"} value={Number(weight)}/> </>}
            {!!volume && <> <S.Volume>{volume}</S.Volume> <MoneyPer taxedMoney={taxedMoney} unitLabel={"L"} value={Number(volume)}/> </>}

        </S.SizeCell>

    }

    const [expandedRows, setExpandedRows] = useState<string[]>([]);


    function NameCell(product: Product_node) {
        const {
            li,
            si,
            wet,
            noise,
            noise_db,
            fuel,
        } = product.extra_data?.attributes || {}
        const {pc} = product.extra_data || {}


        return <S.ProductName>
            <S.SkuBrandModel>
                <S.Sku>SKU: <strong>{product.variants[0].sku}</strong></S.Sku>
                {product.extra_data?.brand && <S.Brand>Bränd: <strong>{product.extra_data.brand}</strong></S.Brand>}
                {product.extra_data?.model && <S.Model>Mudel: <strong>{product.extra_data.model}</strong></S.Model>}
                {pc && <S.Pc>{pc}</S.Pc>}
            </S.SkuBrandModel>
            <ProductDetailLink product={product}><S.Name>{product.name}</S.Name></ProductDetailLink>
            {isTires && <S.TireRatings>
                {si? <S.TireRatingAttr>SI: <strong>{si}</strong></S.TireRatingAttr>:<span/>}
                {li?<S.TireRatingAttr>LI: <strong>{li}</strong></S.TireRatingAttr>:<span/>}
                {wet? <S.TireRatingAttr><ReactSVG path={wetIcon} /> <strong>{wet}</strong></S.TireRatingAttr>: <span/>}
                {fuel?<S.TireRatingAttr><ReactSVG path={fuelIcon} /> <strong>{fuel}</strong></S.TireRatingAttr>: <span/>}
                {noise? <S.TireRatingAttr><ReactSVG path={noiseIcon} /> {noise && <strong>{`${noise} (${noise_db})`}</strong> }</S.TireRatingAttr>: <span/>}
            </S.TireRatings> }
                {isTires || product.category && <S.TagLinks><Trans>Category</Trans>: <Link to={{
                    pathname: generateCategoryUrl(product.category.id, product.category.name),
                }}>{product.category.name}</Link></S.TagLinks>}
                {product.collections.length > 0 && <S.TagLinks><Trans>Tags</Trans>:
                    {product.collections.map((collection, i) => (
                        <Link key={collection.id}
                            to={{pathname: product.category ? generateCategoryCollectionUrl(product.category.id, product.category.name, collection.id, collection.name) : generateCollectionUrl(collection.id, collection.name),
                        }}>{collection.name}{i !== product.collections.length - 1 && ", "}</Link>
                    ))}
                </S.TagLinks>
                }
        </S.ProductName>
    }
    function stockHeader() {
        return <span style={{whiteSpace:"nowrap"}}>{t("Stock")} <i style={{fontSize:"0.8rem"}} className={"pi pi-info-circle"}/> </span>
    }



    function NoStock(){
        return <div style={{textAlign: "center", fontSize:"12px",lineHeight: "normal"}} className="nostock">{t("Ask for availability")} <br/> <i style={{fontSize:"14px"}} className={"pi pi-info-circle"}/> </div>
    }

    function StockCell(product: Product_node) {

        const variant = product.variants[0];
        const warehouseSlugPrefix = "warehouse-";
        if (variant.quantityAvailable <= 0 ){
            return NoStock()
        }

        return <S.Stocks>{
            variant.stocks ?
                variant.stocks.filter(stock => stock.quantity)
                    .sort((a, b) => a.warehouse.order > b.warehouse.order ? 1 : -1)
                    .map((stock: Stock, i, array) => (
                    <React.Fragment key={`${product.id}:${stock.warehouse.slug}`}>
                    <div>
                        <span className="shortname">
                            {stock.warehouse.slug.startsWith(warehouseSlugPrefix) ?
                            stock.warehouse.slug.slice(warehouseSlugPrefix.length) :
                            stock.warehouse.slug
                        }&nbsp;
                        </span>
                        <span className="fullname">
                            {stock.warehouse.name}&nbsp;
                            <span>
                                <strong>{stock.quantity >= 50 ? `50+` : stock.quantity}</strong>
                                <small>{t("pc")}</small>
                                {i !== array.length - 1 && <span className={"separator"}>,</span>}
                            </span>
                        </span>
                    </div>
                        <div className="no-mobile">
                            <strong>{stock.quantity >= 50 ? `50+` : stock.quantity}</strong><small>{t("pc")}</small>
                            {i !== array.length - 1 && <span className={"separator"}>,</span>}
                        </div>
                    </React.Fragment>
                    )) : <span className="quantity ">{variant.quantityAvailable >= 50 ? `50+` : variant.quantityAvailable}<small>{t("pc")}</small></span>}
        </S.Stocks>
    }

    const {updateItem, items, addItem, removeItem, loaded} = useCart();
    const {data: user} = useUserDetails();

    return <div>
            <S.DataTableDiv className="card">
                <DataTable
                    value={products.map(ParseExtraValues)}
                    expandedRows={expandedRows}
                    breakpoint={`${tableBreak}px`}
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    rowExpansionTemplate={ProductExpansion}
                    dataKey={"id"}
                    emptyMessage={
                        loading ? <Loader/> : "No products found"
                    }
                >
                    <Column expander style={{width: '3em'}} body={Expander} />

                    <Column header={t("Images")}
                            style={{width: "10ch"}}
                            body={(product: Product_node) => <LightboxProductImage product={product}>
                            <Thumbnail source={product} fallbackAlt={product.name} />
                        </LightboxProductImage>} />
                    <Column header={t("Products")} body={NameCell} />

                    <Column header={t("Size")}
                            style={{width: "12ch"}}
                            body={Sizing} />


                    <Column header={stockHeader()}
                            body={StockCell}
                            style={{width: "10ch"}}
                            headerClassName={"stockheader"}
                    />

                    <Column header={user ? t("Retail price") : t("Price")}
                            style={{width: "12ch"}}

                            body={(product: Product_node) => {
                                const taxedMoney = product?.pricing?.priceRangeUndiscounted?.start
                                return <S.Price>
                                    <Money money={taxedMoney?.gross} />
                                    <span className={"netmoney"}> <Money money={taxedMoney?.net} /></span>
                                </S.Price>
                            }} />

                    {user && <Column header={t("Client Price")}
                                     style={{width: "12ch"}}
                                     body={(product: Product_node) => {
                                         const taxedMoney = product?.pricing?.priceRange?.start
                                         return <S.Price>
                                             <Money money={taxedMoney?.gross} />
                                             <span className={"netmoney"}> <Money money={taxedMoney?.net} /></span>
                                         </S.Price>
                                     }} />
                    }

                    <Column header={t("Quantity")}
                            style={{width:"16ch"}}
                            body={
                        (product: Product_node) => <ProductAddToCart product={product}
                                                                     key={product.id + loaded || ""} {...{
                            addItem,
                            items: items?.map(e => e),
                            loaded,
                            removeItem,
                            updateItem,
                            user,
                        }} />
                    } />

                </DataTable>
                {canLoadMore && !loading && <S.FlexCenter>

                            <Button
                                data-cy="load-more_button"
                                color="secondary"
                                onClick={onLoadMore}
                            >
                                {t("More")+" +"}
                            </Button>
                    </S.FlexCenter>}
                { !!products.length && loading && <Loader/>
                }
            </S.DataTableDiv>
                {
                    // simple way to re-render the tooltip components so they will hook onto the new dom elements
                    !loading && Tooltips()
                }
        </div>
}

