import { media, styled } from "@styles";

export const Wrapper = styled.div``;

export const Grid = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(2, 1fr);

  ${media.smallScreen`
    grid-template-columns: repeat(1, 1fr);
  `}
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid
    ${props => props.theme.colors.baseFontColorTransparent};
  margin: 0 0 20px 0;
`;

export const Title = styled.h3`
  font-weight: ${props => props.theme.typography.boldFontWeight};
  padding: 0 0 1.6rem 0;
  div {
    font-weight: initial;
    margin-top: 1ch;
    color: red;
    font-size: initial;
  }
`;

export const SubTitle = styled.h4`
  padding: 0.6rem 0 1.4rem 0;
  font-size: ${props => props.theme.typography.baseFontSize};
  color: rgba(50, 50, 50, 0.6);
  i {
        color: ${props => props.theme.colors.dark};
        font-size: 1.6rem;
        position: absolute;
        margin-top: -2px;
  }
  i + span {
  margin-left: 33px;
  }

`;

export const TextSummary = styled.p`
  line-height: 1.6;
  font-size: ${props => props.theme.typography.h4FontSize};
`;

export const ErrorMessages = styled.div`
  margin-top: 30px;
`;

export const DisclamerSection = styled.section`
  grid-column-start: 1;
  grid-column-end: 3;
  ${media.smallScreen`
    grid-column-end: 1;
  `}

`
