import * as React from "react";
import { Link } from "react-router-dom";

import { TaxedMoney } from "@components/containers";
import {
  OrderDetail,
  OrderDetail_lines,
} from "@sdk/fragments/gqlTypes/OrderDetail";

import { AddressSummary, CartTable, NotFound } from "../../../components";
import { ILine } from "../../../components/CartTable/ProductRow";

import {Trans} from "react-i18next";
import { orderHistoryUrl } from "../../../app/routes";

const extractOrderLines = (lines: OrderDetail_lines[]): ILine[] => {
    return lines
    .map(line => ({
      quantity: line.quantity,
      totalPrice: {
        ...line.unitPrice,
        currency: line.unitPrice.currency,
        gross: {
          ...line.unitPrice.gross,
          amount: line.quantity * line.unitPrice.gross.amount,
        },
        net: {
          ...line.unitPrice.net,
          amount: line.quantity * line.unitPrice.net.amount,
        },
      },
      ...line.variant,
      name: line.productName,
    }))
    .sort((a, b) => b.id.toLowerCase().localeCompare(a.id.toLowerCase()));
};

const Page: React.FC<{
  guest: boolean;
  order: OrderDetail;
}> = ({ guest, order }) => {
    return order ? (
        <>
            {!guest && (
                <Link className="order-details__link" to={orderHistoryUrl}>
                    <Trans>Go back to Order History</Trans>
                </Link>
            )}
            <h3><Trans>Your order number is</Trans>: <span>{order.number}</span></h3>
            {/* <p className="order-details__status">
                <Trans>{order.paymentStatusDisplay}</Trans> / <Trans>{order.statusDisplay}</Trans>
            </p> */}
            <CartTable
                lines={extractOrderLines(order.lines)}
                totalCost={<TaxedMoney taxedMoney={order.total} />}
                // deliveryCost={<TaxedMoney taxedMoney={order.shippingPrice} />}
                vat={<TaxedMoney taxedMoney={!order.total ? order.total : {
                    ...order.total,
                    net: {...order.total.net, amount: order.total.gross.amount - order.total.net.amount},
                }} displayGrossPrice={false} />}
                subtotal={<TaxedMoney taxedMoney={order.subtotal} displayGrossPrice={false} />}
            />
            <div className="order-details__summary">
                <div>
                    <h4><Trans>Shipping Address</Trans></h4>
                    <AddressSummary
                        address={order.shippingAddress}
                        email={order.userEmail}
                        paragraphRef={this.shippingAddressRef}
                    />
                </div>
                <div>
                    <h4><Trans>Shipping Method</Trans></h4>
                    {order.shippingMethodName}
                </div>
                {order.shippingRemark &&
                <div><h4><Trans>Shipping Remark</Trans></h4>
                    {order.shippingRemark}
                </div>
                }
            </div>
        </>
    ) : (
        <NotFound />
    );
}
export default Page;
