import { smallScreen } from "../../globalStyles/scss/variables.scss";
import "./scss/index.scss";

import * as React from "react";
import Media from "react-media";

import {useTranslation} from "react-i18next";
import CostRow from "./CostRow";
import ProductRow, { EditableProductRowProps, ILine } from "./ProductRow";

interface TableProps extends EditableProductRowProps {
  lines: ILine[];
  subtotal: React.ReactNode;
  deliveryCost?: React.ReactNode;
  vat?: React.ReactNode;
  totalCost?: React.ReactNode;
  discount?: React.ReactNode;
  discountName?: string;
}

const Table: React.FC<TableProps> = ({
  subtotal,
  deliveryCost,
  totalCost,
  discount,
  vat,
  discountName,
  lines,
  ...rowProps
}) => {
    const {t} = useTranslation();
    return <Media query={{minWidth: smallScreen}}>
        {mediumScreen => (
            <table className="cart-table">
                <thead>
                <tr>
                    <th>{t("Products")}</th>
                    <th>SKU</th>
                    {mediumScreen && <th>{t("Price")} {t("VAT excluded")}</th>}
                    <th className="cart-table__quantity-header">{t("Quantity")}</th>
                    <th colSpan={2}>{t("Total VAT excluded")}</th>
                </tr>
                </thead>
                <tbody>
                {lines.map(line => (
                    <ProductRow
                        key={line.id}
                        line={line}
                        mediumScreen={mediumScreen}
                        {...rowProps}
                    />
                ))}
                </tbody>
                <tfoot>
                <CostRow
                    mediumScreen={mediumScreen}
                    heading={t("Total VAT excluded")}
                    cost={subtotal}
                />
                {discount && (
                    <CostRow
                        mediumScreen={mediumScreen}
                        heading={`Discount: ${discountName}`}
                        cost={discount}
                    />
                )}
                {deliveryCost && (
                    <CostRow
                        mediumScreen={mediumScreen}
                        heading={t("Delivery Cost")}
                        cost={deliveryCost}
                    />
                )}
                {vat && (
                    <CostRow
                        mediumScreen={mediumScreen}
                        heading={t("VAT")}
                        cost={vat}
                    />
                )}
                {totalCost && (
                    <CostRow
                        mediumScreen={mediumScreen}
                        heading={t("Order total")}
                        cost={totalCost}
                    />
                )}
                </tfoot>
            </table>
        )}
    </Media>
};

export default Table;
