import "./scss/index.scss";

import classNames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";

import {useTranslation} from "react-i18next";
import { baseUrl } from "../../app/routes";
import { getDBIdFromGraphqlId, slugify } from "../../core/utils";
import { Category_category } from "../../views/Category/gqlTypes/Category";
import {Collection_collection} from "../../views/Collection/gqlTypes/Collection";
export interface Breadcrumb {
  value: string;
  link: string;
  search?: string;
}

export const extractBreadcrumbs = (category?: Category_category, collection?:Collection_collection,querystring:string=undefined) => {
  const constructLink = (cat?, coll?) => ({
    link: (cat? [
      `/category`,
      `/${slugify(cat.name)}`,
      `/${getDBIdFromGraphqlId(cat.id, "Category")}/`,
    ]:[]).concat(
        coll? [
      `collection`,
      `/${slugify(coll.name)}`,
      `/${getDBIdFromGraphqlId(coll.id, "Collection")}/`,
        ]:[]
    ).join(""),
    value: coll ? coll.name : cat.name,
      ...querystring? {search: querystring}:{},
  });

  if (!category && !collection){
      return []
  }


  let breadcrumbs = [];
  if (category){
    breadcrumbs.push(constructLink(category))

      if (category.ancestors.edges.length) {
        const ancestorsList = category.ancestors.edges.map(edge =>
          constructLink(edge.node)
        );
        breadcrumbs = ancestorsList.concat(breadcrumbs);
      }
  }
  if (collection && category) {
      const lastCategory = category.ancestors.edges.length? category.ancestors.edges[category.ancestors.edges.length-1] : category;
      breadcrumbs.push(constructLink(lastCategory,collection))
  }
  if (collection && !category){
      breadcrumbs.push(constructLink(undefined, collection))
  }

  return breadcrumbs;
};


const Breadcrumbs: React.FC<{
  breadcrumbs: Breadcrumb[];
  querystring?:string;
}> = ({ breadcrumbs,querystring }) => {
    const {t} = useTranslation()

    return <ul className="breadcrumbs">
            <li>
                <Link to={{pathname: baseUrl, search: querystring}}>{t("Home")}</Link>
            </li>
            {breadcrumbs.map((breadcrumb, index) => (
                <li
                    key={breadcrumb.value}
                    className={classNames({
                        breadcrumbs__active: index === breadcrumbs.length - 1,
                    })}
                >
                    <Link to={{pathname: breadcrumb.link, search: breadcrumb.search}}>{breadcrumb.value}</Link>
                </li>
            ))}
        </ul>
};

export default Breadcrumbs;
